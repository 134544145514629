import {MUTATION_INSERT_USERS, MUTATION_REGISTER_NEW_USER} from "../gql";
import React, {useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import useMutationWithDialog from "./useMutationWithDialog";

function AddUserDialog({editFormData, onClose, onMutationCompleted, ...rest}) {


    const defaultUser = {
        first_name: "",
        last_name: "",
        infix: "",
        email: "",
    };
    const {memoComp: MemoInsertSnackbar, confirmMutation: registerNewUser, mutationLoading: registerNewUserLoading} = useMutationWithDialog(
        (editFormData) ? MUTATION_INSERT_USERS : MUTATION_REGISTER_NEW_USER,
        {
            onCompleted: (e) => {
                onMutationCompleted();
            }
        }
    );

    const [formData, setFormData] = useState(defaultUser);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    useEffect(() => {
        if (editFormData) {
            setFormData(editFormData);
        }
    }, [setFormData]);


    return <React.Fragment>
        {MemoInsertSnackbar}
        <Dialog {...rest} fullWidth maxWidth={"lg"}>
            <form autoComplete={"off"} onSubmit={(e) => {
                e.preventDefault();
                registerNewUser({
                    variables: {
                        data: formData
                    }
                }, {
                    dialogTitle: "Nieuwe FysioSporter toevoegen",
                    dialogContent: `Er wordt een e-mail met de inlog gegevens verstuurd naar ${formData.email}. 
                    Deze mail kan eventueel in de SPAM box terecht komen. Graag de FysioSporter hiervan op de hoogte brengen.
                    `,
                    positiveButtonText: "Ik begrijp het!",
                    negativeButtonText: "Annuleren",
                });
            }}>
                <DialogTitle>{(editFormData) ? "Wijzig de gegevens van de FysioSporter" : "Voeg een nieuwe FysioSporter toe"}</DialogTitle>
                <DialogContent>

                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Voornaam</TableCell>
                                <TableCell>Tussenvoegsel</TableCell>
                                <TableCell>Achternaam</TableCell>
                                <TableCell>E-mailadres</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField value={formData.first_name} fullWidth name={"first_name"}
                                               required
                                               placeholder={"voornaam"}
                                               onChange={handleChange}/>
                                </TableCell>
                                <TableCell>
                                    <TextField value={formData.infix} fullWidth name={"infix"}
                                               placeholder={"tussenvoegsel"}
                                               onChange={handleChange}/>
                                </TableCell>
                                <TableCell>
                                    <TextField value={formData.last_name} fullWidth name={"last_name"}
                                               required
                                               placeholder={"achternaam"}
                                               onChange={handleChange}/>
                                </TableCell>
                                <TableCell>
                                    <TextField value={formData.email} fullWidth required type={"email"}
                                               name={"email"}
                                               placeholder={"email"} onChange={handleChange}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Annuleren</Button>
                    <Button type={"submit"} variant={"contained"} color={"primary"} disabled={(
                        (formData.last_name.length <= 0 || formData.first_name.length <= 0 || formData.email.length <= 0 || registerNewUserLoading)
                    )}>Opslaan</Button>
                </DialogActions>
            </form>
        </Dialog>
    </React.Fragment>
};

export default React.memo(AddUserDialog);
import React, {createRef, useMemo} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {useQuery, useSubscription} from '@apollo/react-hooks';
import {render} from 'react-dom';
import AppProgress from "./AppProgress";
import blockedDatesEventSource from "../data/blockedDatesEventSource";
import userLessonsEventSource from "../data/userLessonsEventSource";
import notUserLessonsEventSource from "../data/notUserLessonsEventSource";
import {has} from 'lodash';
import {MAX_OCCUPATIONS} from "./OccupationIndicator";
import lessonEventRender from "../utils/lessonEventRender";
import {Typography} from '@material-ui/core';

import {
    MUTATION_ADD_USERS_TO_LESSON,
    MUTATION_DELETE_USER_FROM_LESSON,
    QUERY_IS_ME,
    SUBSCRIPTION_BLOCKED_DATES,
    SUBSCRIPTION_LESSONS
} from "../gql";
import useMutationWithDialog from "./useMutationWithDialog";
import UserSchedulerLegend from "./UserSchedulerLegend";

export default ({forwardRef, datesRender, ...rest}) => {
    const ref = (forwardRef) ? forwardRef : createRef();
    const {memoComp: MemoDeleteSnackbar, confirmMutation: deleteUserFromLesson} = useMutationWithDialog(MUTATION_DELETE_USER_FROM_LESSON);
    const {memoComp: MemoInsertSnackbar, confirmMutation: addUserToLesson} = useMutationWithDialog(MUTATION_ADD_USERS_TO_LESSON);
    const {data: {me: {id: user_id}}} = useQuery(QUERY_IS_ME);
    useSubscription(SUBSCRIPTION_LESSONS, {
        onSubscriptionData: () => {
            ref.current.calendar.refetchEvents()
        }
    });

    useSubscription(SUBSCRIPTION_BLOCKED_DATES, {
        onSubscriptionData: () => {
            ref.current.calendar.refetchEvents()
        }
    });
    const MemoScheduler = useMemo(() => {
        return <FullCalendar
            {...rest}
            ref={ref}
            loading={(isLoading) => {
                if (isLoading) {
                    render(
                        <AppProgress/>, document.querySelector('#loader')
                    )
                } else {
                    render(null, document.querySelector('#loader'))
                }
            }}
            height={"auto"}
            lazyFetch={false}
            refetchResourcesOnNavigate={true}
            googleCalendarApiKey={"AIzaSyDix_MX87romIBw_GpA2r7r-qnaoVLDkIs"}
            nowIndicator={false}
            locale={nlLocale}
            firstDay={1}
            weekNumbers={true}
            themeSystem="bootstrap"
            defaultView="timeGridWeek"
            header={{
                left: "title today",
                right: "listWeek timeGridWeek dayGridMonth prev next"
            }}
            weekLabel="Week"
            minTime={"08:00"}
            plugins={[
                listPlugin,
                dayGridPlugin,
                timeGridPlugin,
                bootstrapPlugin,
                interactionPlugin,
                googleCalendarPlugin,
            ]}
            viewSkeletonRender={(info) => {
                const calendar = info.view.context.calendar;
                const notUserLessons = calendar.getEventSourceById(notUserLessonsEventSource().id);
                if (info.view.type === 'timeGridWeek') {
                    calendar.refetchEvents();
                }
                if (info.view.type === 'listWeek') {
                    if (notUserLessons) {
                        calendar.getEventSourceById(notUserLessonsEventSource().id).remove();
                    }
                } else {
                    if (!notUserLessons) {
                        calendar.addEventSource(notUserLessonsEventSource());
                    }
                }
                setTimeout(() => {
                    render(
                        <UserSchedulerLegend/>,
                        document.querySelector('.fc-center'));
                }, 10)
            }}
            datesRender={(info) => {
                datesRender(info);
                const calendar = info.view.context.calendar;
                const notUserLessons = calendar.getEventSourceById(notUserLessonsEventSource().id);
                if (info.view.type === 'listWeek') {
                    if (notUserLessons) {
                        calendar.getEventSourceById(notUserLessonsEventSource().id).remove();
                    }
                } else {
                    if (!notUserLessons) {
                        calendar.addEventSource(notUserLessonsEventSource());
                    }
                }
                setTimeout(() => {
                    render(
                        <UserSchedulerLegend/>,
                        document.querySelector('.fc-center'));
                }, 10)
            }}
            eventClick={(info) => {
                const {event, el} = info;
                const {start, end} = event;
                if (has(info.event.extendedProps, "lesson")) {
                    const {lesson} = event.extendedProps;
                    const {id: current_lesson_id} = lesson;
                    const {count} = lesson.users_lessons_aggregate.aggregate;
                    const {class_schedule: {from_time, to_time}} = lesson.lessons_class_schedules[0];
                    const fromToTimeString = from_time + " - " + to_time + " uur";

                    if (info.event.end.getTime() < new Date().getTime()
                        || (count >= MAX_OCCUPATIONS
                            && !has(info.event.extendedProps, "userLessonEvent"))
                    ) {
                        return
                    }

                    if (has(info.event.extendedProps, "userLessonEvent")) {
                        deleteUserFromLesson({
                            variables: {
                                where: {
                                    lesson_id: {_eq: current_lesson_id}
                                }
                            }
                        }, {
                            dialogTitle: "Fysiosportles annuleren",
                            dialogContent: <Typography>Weet u zeker dat u de les
                                op {window.moment(lesson.date).format('dddd DD MMMM YYYY')}, <br/> {fromToTimeString} wil
                                annuleren?</Typography>,
                        });

                    } else {
                        if (count < MAX_OCCUPATIONS) {

                            addUserToLesson({
                                variables: {
                                    data: [
                                        {
                                            id: current_lesson_id,
                                            users_lessons: {
                                                data: {
                                                    user_id
                                                },
                                                on_conflict: {
                                                    constraint: "users_lessons_pkey",
                                                    update_columns: ["user_id"]
                                                }
                                            }
                                        }
                                    ]
                                }
                            }, {
                                dialogTitle: "Fysiosportles inplannen",
                                dialogContent: <Typography>Weet u zeker dat u de les
                                    op {window.moment(lesson.date).format('dddd DD MMMM YYYY')}, <br/> {fromToTimeString} wil
                                    inplannen?</Typography>,
                            });
                        }
                    }
                }
            }}
            eventRender={(info) => {
                lessonEventRender(info, {admin: false});
            }}
            eventSources={[
                blockedDatesEventSource,
                notUserLessonsEventSource(),
                userLessonsEventSource(),
                {
                    googleCalendarId: "nl.dutch#holiday@group.v.calendar.google.com",
                    className: 'gcal-event',
                    color: "lightgreen",
                    textColor: "black"
                }
            ]}
        />
    }, [ref]);
    return (
        <React.Fragment>
            {MemoInsertSnackbar}
            {MemoDeleteSnackbar}
            {MemoScheduler}
        </React.Fragment>
    );
};

import React, {useMemo, useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {Button, CircularProgress, LinearProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {has} from "lodash";

export default (mutation, mutationHookOptions) => {
    const [mutationFunctionOptions, setMutationFunctionOptions] = useState();
    const [snackbarOptions, setSnackbarOptions] = useState({
        open: false,
        serverity: "success",
        message: "",
    });
    const [dialogOptions, setDialogOptions] = useState({
        open: false,
        dialogTitle: "Ter bevestiging",
        dialogContent: "Weet u het zeker dat u deze handeling wilt verrichten?",
        positiveButtonText: "Ja, zeker!",
        negativeButtonText: "Annuleren",
    });

    const [_doMutation, {loading: mutationLoading}] = useMutation(mutation, {
        ...mutationHookOptions,
        onCompleted: () => {
            if (has(mutationHookOptions, "onCompleted"))
                mutationHookOptions.onCompleted();
            setDialogOptions({
                ...dialogOptions,
                open: false,
            });
            setSnackbarOptions({
                open: true,
                message: "Gegevens opgeslagen",
                serverity: "success",
            });
        },
        onError: (e) => {
            if (has(mutationHookOptions, "onError")) mutationHookOptions.onError(e);
            setDialogOptions({
                ...dialogOptions,
                open: false,
            });
            setSnackbarOptions({
                open: true,
                message: `Opslaan is niet gelukt`,
                serverity: "error",
            });
        },
    });
    return {
        memoComp: useMemo(() => {
            const handleSnackbarClose = () => {
                setSnackbarOptions({open: false});
            };
            return (
                <React.Fragment>
                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                        open={snackbarOptions.open}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarOptions.serverity}
                        >
                            {snackbarOptions.message}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={dialogOptions.open}
                        onClose={() => {
                            setDialogOptions({...dialogOptions, open: false});
                        }}
                    >
                        <DialogTitle>{dialogOptions.dialogTitle}</DialogTitle>
                        <DialogContent>{dialogOptions.dialogContent}</DialogContent>
                        <DialogActions>
                            <Button
                                color={"default"}
                                onClick={async () => {
                                    setDialogOptions({...dialogOptions, open: false});
                                }}
                            >
                                {dialogOptions.negativeButtonText}
                            </Button>
                            <Button
                                disabled={mutationLoading}
                                variant={"text"}
                                color={"primary"}
                                onClick={async () => {
                                    await _doMutation(mutationFunctionOptions);
                                }}
                            >
                                {mutationLoading ? "Aan het opslaan..." : dialogOptions.positiveButtonText}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        }, [dialogOptions, snackbarOptions, setDialogOptions, mutationLoading]),
        confirmMutation: (mutationFunctionOptions, overrideDialogOptions) => {
            setMutationFunctionOptions(mutationFunctionOptions);
            setDialogOptions({
                ...dialogOptions,
                ...overrideDialogOptions,
                open: true,
            });
        },
        mutationLoading
    };
};

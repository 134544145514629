import {apolloClient} from "../apolloClient";
import {QUERY_BLOCKED_DATES} from "../gql";

export default {
    id: "blockedDatesEventSource",
    events: async ({end}, successCallback, failureCallback) => {
        const startDateString = window.moment().format('YYYY-MM-DD'),
            endDateString = window.moment(end).format('YYYY-MM-DD');
        try {
            const {data: {blocked_dates}} = await apolloClient.query({
                fetchPolicy: "no-cache",
                query: QUERY_BLOCKED_DATES, variables: {
                    where: {
                        // _and: [{
                        //     date: {_gte: startDateString},
                        // }, {
                        //     date: {_lte: endDateString},
                        // }]
                    }
                }
            });
            successCallback(blocked_dates.map((blockedDate) => {
                const {date: dateString, title} = blockedDate;
                return {
                    start: dateString,
                    title,
                    allDay: true,
                    extendedProps: {
                        blockedDateEvent: true,
                        blockedDate
                    }
                }
            }));
        } catch (e) {
            failureCallback(e);
        }
    },
    className: "blocked-date-event",
    color: "orangered",
    textColor: "black"
};
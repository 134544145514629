export const filterNotInThePast = (lessonEvent) => {
    const endMomentDate = window.moment(lessonEvent.end, 'YYYY-MM-DDTHH:mm');
    return (endMomentDate._d.getTime() >= new Date().getTime());
};
export default (lessons, overrideEventObject) => {
    return lessons.map((lesson) => {
        const {date: dateString, id, lessons_class_schedules} = lesson;
        const {class_schedule} = lessons_class_schedules[0];
        const {from_time, to_time} = class_schedule;
        return {
            start: `${dateString}T${from_time}`,
            end: `${dateString}T${to_time}`,
            extendedProps: {
                lessonEvent: true,
                lesson
            },
            ...overrideEventObject
        }
    });
}
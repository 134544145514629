import React, {useState} from "react";
import {MUTATION_ADD_USERS_TO_LESSON, MUTATION_DELETE_USER_FROM_LESSON, SUBSCRIPTION_LESSONS} from "../gql/index";
import {useQuery, useSubscription} from "@apollo/react-hooks";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import AppProgress from "./AppProgress";
import {QUERY_LESSON_BY_ID} from "../gql";
import OccupationIndicator from "./OccupationIndicator";
import SlideTransition from "./SlideTransition";
import UsersAutocomplete from "./UsersAutocomplete";
import useMutationWithDialog from "./useMutationWithDialog";
import useMutationWithSnackbar from "./useMutationWithSnackbar";
import {userNameFormatter} from '../utils/formatters';
import {NavLink as Link} from 'react-router-dom';


export default function ({lesson, modalOpen, onClose}) {
    const {dateString, lessonTimeString, lesson_id} = lesson;
    const [deletedUsers, setDeletedUsers] = useState([]);

    const lessonResult = useQuery(QUERY_LESSON_BY_ID, {
        variables: {
            lesson_id
        }
    });
    const [selectedAddUsers, setSelectedAddUsers] = useState([]);

    const {memoComp: DeleteUserConfirmationDialog, mutationLoading: deleteMutationLoading, confirmMutation: deleteUserFromLesson} = useMutationWithDialog(
        MUTATION_DELETE_USER_FROM_LESSON,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: QUERY_LESSON_BY_ID,
                    variables: {
                        lesson_id
                    }
                }
            ]
        }
    );
    const {memoComp: MemoSnackbar, doMutation: addUsersToLesson, mutationResult: addUsersToLessonResult} = useMutationWithSnackbar(MUTATION_ADD_USERS_TO_LESSON, {
        refetchQueries: [
            {
                query: QUERY_LESSON_BY_ID,
                variables: {
                    lesson_id
                }
            }
        ],
        onCompleted: () => {
            setSelectedAddUsers([]);
        }
    });

    useSubscription(SUBSCRIPTION_LESSONS, {
        onSubscriptionData: () => {
            lessonResult.refetch({
                lesson_id
            })
        },
        variables: {
            where: {}
        }
    });

    return lessonResult.loading ? (
        <AppProgress/>
    ) : lessonResult.data && lessonResult.data.lessons_by_pk ? (
        <React.Fragment>
            {MemoSnackbar}
            {DeleteUserConfirmationDialog}
            <Dialog
                TransitionComponent={SlideTransition}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="simple-dialog-title"
                open={modalOpen}
            >
                {deleteMutationLoading && <LinearProgress/>}
                <DialogTitle id="simple-dialog-title">
                    <div style={{float: "left"}}>{window.moment(dateString, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')}</div>
                    <div style={{float: "right"}}>
                        <Icon>schedule</Icon> {lessonTimeString}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{width: "100%"}}>
                        <Box display="flex">
                            <Box flexGrow={1} pr={3}>
                                <UsersAutocomplete
                                    excludedIds={lessonResult.data.lessons_by_pk.users_lessons.map(({user}) => user.id)}
                                    value={selectedAddUsers}
                                    onChange={(e, value) => {
                                        setSelectedAddUsers(value);
                                    }}/>
                            </Box>
                            <Box pt={1.5}>
                                <Button disabled={(selectedAddUsers.length <= 0)} color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            addUsersToLesson({
                                                variables: {
                                                    data: selectedAddUsers.map(({id: user_id}) => {
                                                        return {
                                                            id: lesson_id,
                                                            users_lessons: {
                                                                data: {
                                                                    user_id
                                                                },
                                                                on_conflict: {
                                                                    constraint: "users_lessons_pkey",
                                                                    update_columns: ["user_id"]
                                                                }
                                                            }
                                                        }
                                                    })
                                                }
                                            })
                                        }}>Toevoegen</Button>
                            </Box>
                        </Box>
                    </div>
                    <hr/>
                    {lessonResult.data.lessons_by_pk.users_lessons.length > 0 ? <div>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box flexGrow={1}>
                                <Typography variant="h6" component="h6">
                                    Ingeplande FysioSporters
                                </Typography>
                            </Box>
                            <Box>
                                <OccupationIndicator
                                    currentOccupations={lessonResult.data.lessons_by_pk.users_lessons_aggregate.aggregate.count}/>
                            </Box>
                        </Box>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Naam</TableCell>
                                    <TableCell align="right">&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lessonResult.data.lessons_by_pk.users_lessons.map(
                                    ({user: {email, last_name, infix, first_name, id}}, index) => {
                                        return <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <Button endIcon={<Icon>event</Icon>} to={"/dashboard/user/" + id}
                                                        component={Link} variant={"text"}>
                                                    {first_name + " " + (infix ? infix : "") + " " + last_name}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    disabled={deletedUsers.find(user => user.id === id) && deleteMutationLoading}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        const findUser = lessonResult.data.lessons_by_pk.users_lessons.find(
                                                            item => item.user.id === id
                                                        );
                                                        setDeletedUsers([findUser.user, ...deletedUsers]);
                                                        deleteUserFromLesson({
                                                            variables: {
                                                                where: {
                                                                    user_id: {_eq: findUser.user.id},
                                                                    lesson_id: {_eq: lesson_id}
                                                                }
                                                            }
                                                        }, {
                                                            dialogTitle: `${window.moment(dateString).format('dddd DD MMMM YYYY')} om ${lessonTimeString}`,
                                                            dialogContent: `Wilt u ${userNameFormatter(findUser.user)} uit deze les halen?`
                                                        });
                                                    }}
                                                >
                                                    {deletedUsers.find(user => user.id === id) && deleteMutationLoading && (
                                                        <CircularProgress size={24} color="secondary"/>
                                                    )}
                                                    &nbsp;<Icon>delete</Icon>&nbsp; Verwijderen
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </div> : <Typography variant={"h5"}>Geen FysioSporters ingepland</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleteMutationLoading} variant={"outlined"} onClick={() => {
                        setDeletedUsers([]);
                        onClose();
                    }} color="primary">
                        Sluiten
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    ) : null;
}

import OccupationIndicator, {
  MAX_OCCUPATIONS,
} from "../components/OccupationIndicator";
import React from "react";
import { render } from "react-dom";
import { has } from "lodash";
import { Typography } from "@material-ui/core";

export default (info, { admin }) => {
  const { event, el } = info;
  const { start, end } = event;
  const { lesson } = event.extendedProps;
  if (has(info.event.extendedProps, "lesson")) {
    const { count } = lesson.users_lessons_aggregate.aggregate;
    const {
      class_schedule: { from_time, to_time },
    } = lesson.lessons_class_schedules[0];
    const fromToTimeString = from_time + " - " + to_time + " uur";
    if (
      end.getTime() >= new Date().getTime() &&
      start.getTime() <= new Date().getTime()
    ) {
      el.classList.add("lesson-now");
      if (!admin) el.classList.add("max-occupied");
    } else if (
      count >= MAX_OCCUPATIONS ||
      end.getTime() < new Date().getTime()
    ) {
      if (!has(info.event.extendedProps, "userLessonEvent"))
        el.classList.add("max-occupied");
    }
    render(
      <div style={{ padding: 3 }}>
        <Typography variant={"caption"}>
          {fromToTimeString}{" "}
          {has(info.event.extendedProps, "userLessonEvent") ? "(uw les)" : ""}
        </Typography>
        <br />
        <OccupationIndicator currentOccupations={count} />
      </div>,
      el
    );
  }
};

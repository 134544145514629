import React, {useMemo} from "react";
import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {render} from 'react-dom';
import AppProgress from "./AppProgress";
import blockedDatesEventSource from "../data/blockedDatesEventSource";
import userLessonsEventSource from "../data/userLessonsEventSource";
import {has} from 'lodash';
import {MAX_OCCUPATIONS} from "./OccupationIndicator";
import lessonEventRender from "../utils/lessonEventRender";
import {Typography} from '@material-ui/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {MUTATION_DELETE_USER_FROM_LESSON, SUBSCRIPTION_BLOCKED_DATES, SUBSCRIPTION_LESSONS} from "../gql";
import useMutationWithDialog from "./useMutationWithDialog";
import {useSubscription} from '@apollo/react-hooks';

let ref;
export default ({forwardRef, user_id, ...rest}) => {
    const {memoComp: MemoDeleteSnackbar, confirmMutation: deleteUserFromLesson} = useMutationWithDialog(MUTATION_DELETE_USER_FROM_LESSON);

    useSubscription(SUBSCRIPTION_LESSONS, {
        onSubscriptionData: () => {
            ref.calendar.refetchEvents()
        }
    });

    useSubscription(SUBSCRIPTION_BLOCKED_DATES, {
        onSubscriptionData: () => {
            ref.calendar.refetchEvents()
        }
    });

    const MemoScheduler = useMemo(() => {
        return <FullCalendar
            {...rest}
            ref={(_ref) => ref = _ref}
            loading={(isLoading) => {
                if (isLoading) {
                    render(
                        <AppProgress/>, document.querySelector('#loader')
                    )
                } else {
                    render(null, document.querySelector('#loader'))
                }
            }}
            height={"auto"}
            lazyFetch={false}
            refetchResourcesOnNavigate={true}
            googleCalendarApiKey={"AIzaSyDix_MX87romIBw_GpA2r7r-qnaoVLDkIs"}
            nowIndicator={false}
            locale={nlLocale}
            firstDay={1}
            weekNumbers={true}
            themeSystem="bootstrap"
            defaultView="dayGridMonth"
            header={{
                left: "title today",
                right: "listWeek timeGridWeek dayGridMonth prev next"
            }}
            weekLabel="Week"
            minTime={"08:00"}
            plugins={[
                listPlugin,
                dayGridPlugin,
                timeGridPlugin,
                bootstrapPlugin,
                interactionPlugin,
                googleCalendarPlugin,
            ]}
            eventClick={(info) => {
                const {event, el} = info;
                const {start, end} = event;
                if (has(info.event.extendedProps, "lesson")) {
                    const {lesson} = event.extendedProps;
                    const {id: current_lesson_id} = lesson;
                    const {count} = lesson.users_lessons_aggregate.aggregate;
                    const {class_schedule: {from_time, to_time}} = lesson.lessons_class_schedules[0];
                    const fromToTimeString = from_time + " - " + to_time + " uur";

                    if (info.event.end.getTime() < new Date().getTime()
                        || (count >= MAX_OCCUPATIONS
                            && !has(info.event.extendedProps, "userLessonEvent"))
                    ) {
                        return
                    }

                    if (has(info.event.extendedProps, "userLessonEvent")) {
                        deleteUserFromLesson({
                            variables: {
                                where: {
                                    lesson_id: {_eq: current_lesson_id}
                                }
                            },
                        }, {
                            dialogTitle: "Fysiosportles annuleren",
                            dialogContent: <Typography>Weet u zeker dat u de les
                                op {window.moment(lesson.date).format('dddd DD MMMM YYYY')}, <br/> {fromToTimeString} wil
                                annuleren?</Typography>,
                        });

                    }
                }
            }}
            eventRender={(info) => {
                lessonEventRender(info, {admin: false});
            }}
            eventSources={[
                blockedDatesEventSource,
                userLessonsEventSource(user_id),
                {
                    googleCalendarId: "nl.dutch#holiday@group.v.calendar.google.com",
                    className: 'gcal-event',
                    color: "lightgreen",
                    textColor: "black"
                }
            ]}
            eventSourceSuccess={function (content, xhr) {
                return content.eventArray;
            }}
        />
    }, [user_id]);
    return (
        <React.Fragment>
            {MemoDeleteSnackbar}
            {MemoScheduler}
        </React.Fragment>
    );
};

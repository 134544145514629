import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {HttpLink} from "apollo-link-http";
import {setContext} from "apollo-link-context";
import {resolvers, typeDefs} from "./gql";
import {onError} from "apollo-link-error";
import {from} from "apollo-link";
import {WebSocketLink} from "apollo-link-ws";
import {SubscriptionClient} from "subscriptions-transport-ws";


export const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
    addTypename: false
});

const getAuthToken = () => {
    const token = window.localStorage.getItem("fysiosport-token");
    return token ? `Bearer ${token}` : "";
};
const authLink = setContext((_, {headers}) => {

    return {
        headers: {
            ...headers,
            authorization: getAuthToken()
        }
    };
});

const onErrorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
            console.log(
                `[GraphQL error]: Message: ${JSON.stringify(
                    message,
                    null,
                    4
                )}, Location: ${JSON.stringify(
                    locations,
                    null,
                    4
                )}, Path: ${JSON.stringify(path, null, 4)}`
            )
        );

    if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError, null, 4)}`);
});


const httpLinkForLogin = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_FOR_LOGIN || "http://localhost:3000/graphql"
});

export const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_ENDPOINT || "ws://localhost:8080/v1/graphql", {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    connectionParams: async () => {
        const token = await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(window.localStorage.getItem("fysiosport-token"))
            }, 0);
        });
        return {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        }
    },

});

subscriptionClient.connectionCallback = err => {
    console.log('subscriptionClient.connectionCallback', err);
    if (err && err.length > 0 && err === 'Could not verify JWT: JWTIssuedAtFuture') {
    }
};

export const apolloClient = new ApolloClient({
    connectToDevTools: true,
    cache,
    link: from([authLink, onErrorLink, new WebSocketLink(subscriptionClient)]),
    // defaltOptions: {
    //    query: {
    //       fetchPolicy: 'no-cache',
    //       errorPolicy: 'all',
    //     }
    // },
    typeDefs,
    resolvers
});

export const apolloClientForLogin = new ApolloClient({
    connectToDevTools: true,
    cache,
    link: from([authLink, onErrorLink, httpLinkForLogin]),
    // defaltOptions: {
    //    query: {
    //       fetchPolicy: 'no-cache',
    //       errorPolicy: 'all',
    //     }
    // },
    typeDefs,
    resolvers
});

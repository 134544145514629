import {apolloClient} from "../apolloClient";
import {QUERY_BLOCKED_DATES, QUERY_IS_ME, QUERY_LESSONS} from "../gql";
import lessonEventMapper from "../utils/lessonEventMapper";

export default (userId) => {
    let user_id;
    return {
        id: "notUserLessonsEventSource",
        events: async ({end}, successCallback, failureCallback) => {
            const startDateString = window.moment().format('YYYY-MM-DD'),
                endDateString = window.moment(end).format('YYYY-MM-DD');
            try {
                if (userId) {
                    user_id = userId;
                } else {
                    const {data: {me}} = await apolloClient.query({
                        query: QUERY_IS_ME
                    });
                    user_id = me.id;
                }
                const {data: {blocked_dates}} = await apolloClient.query({
                    fetchPolicy: "no-cache",
                    query: QUERY_BLOCKED_DATES
                });

                const {data: {lessons}} = await apolloClient.query({
                    fetchPolicy: "no-cache",
                    query: QUERY_LESSONS, variables: {
                        where: {
                            _and: [
                                {
                                    date: {_gte: startDateString},
                                }, {
                                    date: {_lte: endDateString},
                                },
                                {
                                    _not: {
                                        date: {_in: blocked_dates.map(({date}) => date)}
                                    }
                                },
                                {
                                    _not: {
                                        users_lessons: {
                                            user: {
                                                id: {_eq: user_id}
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    }
                });
                successCallback(lessonEventMapper(lessons));
            } catch (e) {
                failureCallback(e);
            }
        },
        className: "lesson-event",
        color: "#3f51b5"
    }
};
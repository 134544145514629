import React from 'react';
import {
    LinearProgress,
    Box
} from "@material-ui/core";

export default function ({...rest}) {
    const style = {position: "absolute", top: 0, left: 0, zIndex: 99};
    return (

        <div {...rest} style={style}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <LinearProgress style={{width: "100vw"}}/>
            </Box>
        </div>
    )
}
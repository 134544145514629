import React, {useMemo, useState} from "react";

export default ({component: DialogComponent, ...rest}) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [data, setData] = useState();
    return {
        memoComp: useMemo(() => {
            return (
                <React.Fragment>
                    <DialogComponent
                        {...rest}
                        data={data}
                        open={dialogOpen}
                        onClose={() => {
                            setDialogOpen(false);
                        }}
                    />
                </React.Fragment>
            );
        }, [dialogOpen]),
        openDialog: (data) => {
            setDialogOpen(true);
            setData(data);
        },
        closeDialog: () => {
            setDialogOpen(false);
        },
    };
};

import LiteUserScheduler from "../components/LiteUserScheduler";
import React, {useEffect, useState} from "react";
import {NavLink as Link, useParams} from 'react-router-dom';
import {QUERY_USERS_BY_PK} from "../gql";
import {Button, Card, CardContent, Grid, Typography, Icon} from '@material-ui/core';
import {useApolloClient, useQuery} from '@apollo/react-hooks';
import {DATE_FORMAT, userNameFormatter} from "../utils/formatters";
import gql from "graphql-tag";

export default (props) => {
    const client = useApolloClient();
    let {user_id} = useParams();
    user_id = Number(user_id);
    const [calendar, setCalendar] = useState();
    const [nextLesson, setNextLesson] = useState();


    useEffect(() => {
        (async () => {
            const usersLessonsResult = await client.query({
                query: gql`
                    query users_lessons($where: users_lessons_bool_exp!) {
                        users_lessons(order_by: {lesson:{date: asc}} where:$where) {
                            lesson{
                                date
                                date_from_time
                                date_to_time
                            }
                        }
                    }
                `,
                variables: {
                    "where": {
                        "user_id": {
                            "_eq": user_id
                        }
                    }
                }
            });
            const users_lessons = usersLessonsResult.data.users_lessons.filter(({lesson: {date_from_time, date_to_time}}) => {
                return window.moment(date_from_time, 'YYYY-MM-DDTHH:mm')._d.getTime() > new Date().getTime()
            });
            setNextLesson(users_lessons[0]);
        })();
    }, [user_id]);


    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        infix: ""
    });

    useQuery(QUERY_USERS_BY_PK, {
        variables: {
            id: user_id
        },
        onCompleted: ({users_by_pk}) => {
            setUser(users_by_pk);
        }
    });


    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Card>
                        <CardContent>
                            <Typography variant={"h6"} component={"h6"}>Agenda
                                van {userNameFormatter(user)}</Typography>
                            <Typography variant={"caption"} component={"p"}>
                                vandaag is: {window.moment().format('dddd DD MMMM YYYY')}</Typography>

                            <br/>


                            {nextLesson ?
                                <React.Fragment>
                                    <Typography variant={"subtitle2"} component={"p"}>Eerste volgende les: </Typography>
                                    <br/>
                                    <Button variant={"contained"} color={"primary"} onClick={() => {
                                        calendar.gotoDate(nextLesson.lesson.date);
                                    }}>{
                                        window.moment(nextLesson.lesson.date)
                                            .format(DATE_FORMAT.FULL_DATE)
                                        + ", "
                                        + window.moment(nextLesson.lesson.date_from_time)
                                            .format(DATE_FORMAT.LESSON_TIME)
                                        + " - "
                                        + window.moment(nextLesson.lesson.date_to_time)
                                            .format(DATE_FORMAT.LESSON_TIME)} uur </Button>
                                </React.Fragment>
                                : <React.Fragment>
                                    <Typography variant={"subtitle1"} component={"p"}>Geen lessen
                                        ingepland</Typography>
                                    <br/>
                                    <Button variant={"contained"} color={"primary"} component={Link}
                                            to={"/dashboard/annual-events/" + user_id}>
                                        <Icon>event</Icon>&nbsp; De lessen inplannen
                                    </Button>
                                </React.Fragment>}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <LiteUserScheduler
                        user_id={user_id}
                        datesRender={(info) => {
                            setCalendar(info.view.context.calendar);
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
import React, {createRef, useMemo, useState} from 'react';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from "date-fns/locale/nl";
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    Typography
} from '@material-ui/core';
import {useQuery, useSubscription} from '@apollo/react-hooks';
import {QUERY_BLOCKED_DATES, SUBSCRIPTION_BLOCKED_DATES} from "../gql";
import AppProgress from "./AppProgress";
import ClassScheduleViewer from "./ClassScheduleViewer";

export default (
    {onChange, forwardRef, ...rest}) => {
    const [date, changeDate] = useState(new Date());
    const ref = (forwardRef) ? forwardRef : createRef();
    const blockedDatesResult = useQuery(QUERY_BLOCKED_DATES);
    const [modalOpen, setModalOpen] = useState(false);

        useSubscription(SUBSCRIPTION_BLOCKED_DATES, {
        onSubscriptionData: () => blockedDatesResult.refetch(),
        variables: {
            where: {}
        }
    });


    // console.log("render LessonsStatic Calendar with Modal Class Schedule");

    const MemoDatePicker = useMemo(() => {
        // console.log("render the actual  Static Calendar DatePicker");
        return <DatePicker
            shouldDisableDate={(date) => {
                return blockedDatesResult.data.blocked_dates
                    .map(({date: dateString}) => dateString)
                    .indexOf(window.moment(date).format('YYYY-MM-DD')) !== -1;
            }}
            orientation="landscape"
            autoOk
            variant="static"
            openTo="date"
            onChange={onChange}
            disablePast={true}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                const isSelected = isInCurrentMonth && blockedDatesResult.data.blocked_dates
                    .map(({date: dateString}) => dateString)
                    .indexOf(window.moment(day).format('YYYY-MM-DD')) !== -1;
                return <Badge badgeContent={isSelected ? "❌" : undefined}
                              color={"default"}>{dayComponent}</Badge>;
            }}
            {...rest}
        />
    }, [blockedDatesResult, onChange]);


    return (
        (blockedDatesResult.loading || !blockedDatesResult.data) ? <AppProgress/> :
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                <Dialog open={modalOpen} fullWidth maxWidth={"lg"}>
                    <DialogTitle><Typography variant={"h6"} color={"primary"}
                                             component={"span"}><Icon fontSize={"large"}>schedule</Icon>&nbsp; FysioSport lestijden en
                        dagen</Typography></DialogTitle>
                    <DialogContent>
                        <Grid container style={{width: '100%', flexGrow: 1, paddingBottom: 20}} spacing={4}>
                            <ClassScheduleViewer cardHeight={220} gridItems={2}
                                                 renderItems={({from_time, to_time, id, day}) => {
                                                     return <Box pt={2} key={id + from_time + to_time + day}>
                                                         <Typography
                                                             variant={"subtitle2"}>{from_time} - {to_time} uur</Typography>
                                                     </Box>
                                                 }}/>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} color={"primary"} onClick={() => {
                            setModalOpen(false);
                        }}>Sluiten</Button>
                    </DialogActions>
                </Dialog>
                {MemoDatePicker}
                <Box p={4}>
                    <Button color={"primary"} size={"large"} fullWidth variant={"contained"} onClick={() => {
                        setModalOpen(true);
                    }}><Icon>query_builder</Icon> &nbsp; Lesdagen en tijden</Button>
                </Box>
            </MuiPickersUtilsProvider>
    )
};

/**

 */
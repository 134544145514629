export const DATE_FORMAT = {
    FULL_DATE: 'dddd DD MMMM YYYY',
    CALENDAR_DATETIME: 'YYYY-MM-DDTHH:mm',
    SIMPLE_DATETIME: 'YYYY-MM-DD HH:mm',
    LESSON_TIME: 'HH:mm'
};

export const userNameFormatter = (user) => {
    return user.first_name + " " + ((user.infix) ? user.infix + " " : "") + user.last_name
};

export const classScheduleTimeFormatter = (classSchedule) => {
    return classSchedule.day + " " + classSchedule.from_time + " - " + classSchedule.to_time + " uur";
};

export const formatToDateTimeString = (lesson) => {
    const {date: dateString, lessons_class_schedules} = lesson;
    const {class_schedule: {from_time, to_time}} = lessons_class_schedules[0];
    const fromMomentDate = window.moment(`${dateString} ${from_time}`, DATE_FORMAT.SIMPLE_DATETIME);
    const toMomentDate = window.moment(`${dateString} ${to_time}`, DATE_FORMAT.SIMPLE_DATETIME);
    return `${fromMomentDate.format(DATE_FORMAT.FULL_DATE)}
        om ${fromMomentDate.format(DATE_FORMAT.LESSON_TIME)} -  ${toMomentDate.format(DATE_FORMAT.LESSON_TIME)} uur`;
};
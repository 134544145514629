import React, {createRef, useMemo, useState, useEffect} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {useSubscription} from '@apollo/react-hooks';
import {render} from 'react-dom';
import AppProgress from "./AppProgress";
import blockedDatesEventSource from "../data/blockedDatesEventSource";
import {has} from 'lodash';
import lessonEventRender from "../utils/lessonEventRender";
import {SUBSCRIPTION_BLOCKED_DATES, SUBSCRIPTION_LESSONS} from "../gql";
import UsersLessonsDialog from "./UsersLessonsDialog";
import lessonsEventSource from "../data/lessonsEventSource";
import AdminSchedulerLegend from "./AdminSchedulerLegend";

export default ({forwardRef, datesRender, ...rest}) => {
    const ref = (forwardRef) ? forwardRef : createRef();
    const [lesson, setLesson] = useState({
        lesson_id: null,
        dateString: "",
        startTimeString: "",
        endTimeString: "",
        lessonTimeString: "",
        users: [],
        occupied: 0
    });
    const [modalOpen, setModalOpen] = useState(false);

    useSubscription(SUBSCRIPTION_LESSONS, {
        onSubscriptionData: () => {
            ref.current.calendar.refetchEvents()
        }
    });

    useSubscription(SUBSCRIPTION_BLOCKED_DATES, {
        onSubscriptionData: () => {
            ref.current.calendar.refetchEvents()
        }
    });
    const MemoScheduler = useMemo(() => {
        return <FullCalendar
            {...rest}
            ref={ref}
            loading={(isLoading) => {
                if (isLoading) {
                    render(
                        <AppProgress/>, document.querySelector('#loader')
                    )
                } else {
                    render(null, document.querySelector('#loader'))
                }
            }}
            height={"auto"}
            lazyFetch={false}
            refetchResourcesOnNavigate={true}
            googleCalendarApiKey={"AIzaSyDix_MX87romIBw_GpA2r7r-qnaoVLDkIs"}
            nowIndicator={false}
            locale={nlLocale}
            firstDay={1}
            weekNumbers={true}
            themeSystem="bootstrap"
            defaultView="timeGridWeek"
            header={{
                left: "title today",
                right: "timeGridWeek dayGridMonth prev next"
            }}
            weekLabel="Week"
            minTime={"08:00"}
            plugins={[
                listPlugin,
                dayGridPlugin,
                timeGridPlugin,
                bootstrapPlugin,
                interactionPlugin,
                googleCalendarPlugin,
            ]}
            viewSkeletonRender={(info) => {
                const calendar = info.view.context.calendar;
                if (info.view.type === 'timeGridWeek') {
                    calendar.refetchEvents();
                }
            }}
            eventClick={(info) => {
                const {event, el} = info;
                const {start, end} = event;
                if (has(info.event.extendedProps, "lesson")) {
                    const {lesson} = event.extendedProps;
                    const {id: current_lesson_id, date: dateString} = lesson;
                    const {count} = lesson.users_lessons_aggregate.aggregate;
                    const {users_lessons: users} = lesson;
                    const {class_schedule: {from_time, to_time}} = lesson.lessons_class_schedules[0];
                    const fromToTimeString = from_time + " - " + to_time + " uur";
                    setModalOpen(true);
                    setLesson({
                        lesson_id: current_lesson_id,
                        dateString,
                        startTimeString: from_time,
                        endTimeString: to_time,
                        lessonTimeString: fromToTimeString,
                        users,
                        occupied: count
                    })
                }
            }}
            eventRender={(info) => {
                lessonEventRender(info, {admin: true})
            }}
            eventSources={[
                blockedDatesEventSource,
                lessonsEventSource,
                {
                    googleCalendarId: "nl.dutch#holiday@group.v.calendar.google.com",
                    className: 'gcal-event',
                    color: "lightgreen",
                    textColor: "black"
                }
            ]}
            datesRender={(info) => {
                setTimeout(() => {
                    render(
                        <AdminSchedulerLegend/>,
                        document.querySelector('.fc-center'));
                }, 0);
                datesRender(info);
            }}
        />
    }, [ref]);
    return (
        <React.Fragment>
            {modalOpen && <UsersLessonsDialog
                modalOpen={modalOpen}
                onClose={async () => {
                    setModalOpen(false);
                }}
                lesson={lesson}
            />}
            {MemoScheduler}
        </React.Fragment>
    );
};

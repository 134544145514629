import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { apolloClientForLogin } from "../apolloClient";

const MUTATION_LOGIN = gql`
  mutation login($credentials: LoginMutationInput!) {
    login(credentials: $credentials) {
      token
    }
  }
`;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.theuy.nl">
        Theuy B.V.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://zwanenzijde.nl/wp-content/uploads/2017/01/plaat-mussenplaats-resized.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const [loadForLogin, setLoadForLogin] = useState(false);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [
    doLogin,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_LOGIN, {
    client: apolloClientForLogin,
    onError: () => {
      setLoadForLogin(false);
    },
    onCompleted: ({ login: { token } }) => {
      localStorage.removeItem("fysiosport-token");
      localStorage.setItem("fysiosport-token", token);
      setLoadForLogin(true);
      setTimeout(() => {
        window.location = "/dashboard";
      }, 3000);
    },
  });

  const submitLoginHandler = async (e) => {
    e.preventDefault();
    doLogin({
      variables: {
        credentials: {
          email,
          password,
        },
      },
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <img
            alt="background"
            src={
              "https://zwanenzijde.nl/wp-content/uploads/2017/01/kngf_merk_rgb.png"
            }
            width="40%"
            height="40%"
          />
          <br />
          <Typography component="h1" variant="h5">
            FysioSport App inloggen
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={submitLoginHandler}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Wachtwoord"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              disabled={loadForLogin}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loadForLogin ? (
                <React.Fragment>
                  <CircularProgress size={24} color={"primary"} />
                  &nbsp; Aan het inloggen
                </React.Fragment>
              ) : (
                <React.Fragment>Inloggen</React.Fragment>
              )}
            </Button>

            <Box mt={1}>
              {mutationLoading && ""}
              {mutationError && (
                <Typography align={"center"} color={"error"}>
                  {mutationError.message
                    .toLowerCase()
                    .indexOf("Authentication failed".toLowerCase()) !== -1
                    ? "De inlog gegevens kloppen niet, probeer het nog een keer a.u.b."
                    : mutationError.message
                        .toLowerCase()
                        .indexOf("Only admin allowed!".toLowerCase()) !== -1
                    ? "Vanwege de COVID-19 maatregelen is dit systeem tijdelijk voor u offline gezet."
                    : "Inloggen niet gelukt, probeer het nog later nog eens."}
                </Typography>
              )}
            </Box>

            <Box mt={2}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

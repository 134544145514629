import React, {createRef, useEffect} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {intersection} from 'lodash';
import {MUTATION_DELETE_BLOCKED_DATES, MUTATION_INSERT_BLOCKED_DATES, QUERY_BLOCKED_DATES} from "../gql";
import {apolloClient} from '../apolloClient';
import AppProgress from "./AppProgress";
import {render} from 'react-dom';
import blockedDatesEventSource from "../data/blockedDatesEventSource";
import {Alert} from "@material-ui/lab";
import {useApolloClient} from '@apollo/react-hooks';


export default ({onCompleted, eventClick, eventRender, eventPositioned, forwardRef, datesRender, ...rest}) => {
    const ref = (forwardRef) ? forwardRef : createRef();
    const client = useApolloClient();
    return (
        <div className={"blocked-dates-calendar"}>
            <FullCalendar
                loading={async (isLoading) => {
                    if (isLoading) {
                        render(
                            <AppProgress/>, document.querySelector('#loader')
                        )
                    } else {
                        render(null, document.querySelector('#loader'))
                    }
                }}
                ref={ref}
                height={"auto"}
                lazyFetch={false}
                refetchResourcesOnNavigate={true}
                {...rest}
                googleCalendarApiKey={"AIzaSyDix_MX87romIBw_GpA2r7r-qnaoVLDkIs"}
                nowIndicator={false}
                locale={nlLocale}
                firstDay={1}
                weekNumbers={true}
                themeSystem="bootstrap"
                defaultView="dayGridMonth"
                header={{
                    left: "title today",
                    right: "dayGridMonth prev next"
                }}
                selectable={true}
                weekLabel="Week"
                minTime={"08:00"}
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    bootstrapPlugin,
                    interactionPlugin,
                    googleCalendarPlugin,
                ]}
                datesRender={async (info) => {
                    setTimeout(() => {
                        render(
                            <Alert variant={"outlined"} severity={"info"}>(De)Blokkeer de lessen door op de datum vakken
                                te
                                slepen of te klikken </Alert>,
                            document.querySelector('.fc-center'));
                    }, 0);

                    datesRender(info);
                }}
                select={async (selectionInfo) => {
                    const {data: {blocked_dates}} = await client.query({
                        query: QUERY_BLOCKED_DATES
                    });

                    const {start, end, view} = selectionInfo;
                    const {activeStart, activeEnd} = view;
                    const selectedDatesRange = window.moment.range(start, end);
                    const currentViewDatesRange = window.moment.range(activeStart, activeEnd);
                    const currentViewDatesString = Array
                        .from(currentViewDatesRange.by('days', {excludeEnd: true}))
                        .map(momentDate => momentDate.format('YYYY-MM-DD'));
                    const blockedDatesString = intersection(currentViewDatesString, blocked_dates.map(({date}) => date));
                    const selectedDatesString = Array.from(selectedDatesRange.by('days', {excludeEnd: true}))
                        .map(momentDate => momentDate.format('YYYY-MM-DD'));

                    let deleteDatesString = selectedDatesString.filter((dateString) => {
                        return (blockedDatesString.indexOf(dateString) !== -1);
                    });
                    let insertDateString = selectedDatesString.filter((dateString) => {
                        return (blockedDatesString.indexOf(dateString) === -1);
                    });

                    try {
                        await apolloClient.mutate({
                            mutation: MUTATION_DELETE_BLOCKED_DATES,
                            variables: {
                                where: {
                                    _or: [
                                        {
                                            date: {_in: deleteDatesString}
                                        }, {
                                            date: {_in: insertDateString}
                                        }
                                    ]
                                }
                            }
                        });

                        await apolloClient.mutate({
                            mutation: MUTATION_INSERT_BLOCKED_DATES,
                            variables: {
                                data: insertDateString.map(dateString => {
                                    return {
                                        date: dateString
                                    }
                                })
                            }
                        });
                        ref.current.calendar.refetchEvents();
                    } catch (e) {
                        console.error(e);
                    }
                }}
                eventClick={(info) => {
                    if (info.event.allDay) {
                        info.el.setAttribute("href", "javascript:void(0)");
                    }
                    ref.current.calendar.select(info.event.start);
                }}
                eventSources={[
                    blockedDatesEventSource,
                    {
                        googleCalendarId: "nl.dutch#holiday@group.v.calendar.google.com",
                        className: 'gcal-event',
                        color: "lightgreen",
                        textColor: "black"
                    }
                ]}
            />
        </div>
    );
}

import React, {useRef, useState, useMemo} from 'react';
import {Autocomplete} from '@material-ui/lab';
import {TextField, InputBase} from '@material-ui/core';
import {useQuery, useSubscription} from '@apollo/react-hooks';
import {QUERY_USERS, SUBSCRIPTION_LESSONS, SUBSCRIPTION_USERS} from "../gql";
import {userNameFormatter} from "../utils/formatters";

const RenderInputBase = ({params, autoFocus, label, classes, ...rest}) => {
    return <InputBase
        {...rest}
        autoFocus={autoFocus}
        variant="outlined"
        label={label}
        placeholder="Typ een naam"
        ref={params.InputProps.ref}
        classes={classes}
        inputProps={params.inputProps}
    />
};

const RenderTextField = ({params, autoFocus, label, ...rest}) => {
    const autoCompleteInput = useRef(null);
    return <TextField
        autoFocus={autoFocus}
        {...params}
        {...rest}
        variant="outlined"
        label={label}
        placeholder="Typ een naam"
        ref={autoCompleteInput}
    />
};
const UsersAutocomplete = ({
                               autoFocus = true,
                               multiple = true,
                               label = "Voeg FysioSporter(s) toe",
                               value,
                               onChange,
                               excludedIds = [],
                               appBarMode = false,
                               classes,
                               ...rest
                           }) => {


    const whereUsers = {
        where: {
            _not: {
                _and: [
                    {
                        email: {_eq: "admin@fysiozwanenzijde.nl"}
                    }
                ]
            }
        }
    };
    const fetchUsersResult = useQuery(QUERY_USERS, {
        variables: whereUsers
    });

    useSubscription(SUBSCRIPTION_USERS, {
        onSubscriptionData: async () => {
            await fetchUsersResult.refetch(whereUsers);
        }
    });

    return (
        <Autocomplete
            {...rest}
            multiple={multiple}
            value={value}
            options={fetchUsersResult.data && fetchUsersResult.data.users ? fetchUsersResult.data.users.filter(_user => {
                return excludedIds.indexOf(_user.id) === -1;
            }) : []}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => userNameFormatter(option)}
            filterSelectedOptions
            renderInput={(params) => {
                return (appBarMode) ? <RenderInputBase label={label} params={params} {...rest} classes={classes}/> :
                    <RenderTextField params={params} label={label} {...rest} />
            }}
            onChange={onChange}
            noOptionsText={"Geen FysioSporter gevonden"}
        />
    );
};

export default UsersAutocomplete;
import React from 'react';
import {Icon} from '@material-ui/core';

export const MAX_OCCUPATIONS = 8;
export default ({currentOccupations}) => {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <Icon>group</Icon> &nbsp; {currentOccupations} &nbsp;&nbsp;&nbsp;&nbsp;
            <Icon>crop_free</Icon> &nbsp; {(MAX_OCCUPATIONS - currentOccupations < 0) ? 0 : MAX_OCCUPATIONS - currentOccupations}
        </div>
    )
}
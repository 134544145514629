import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import UsersAutocomplete from "./UsersAutocomplete";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    colors,
    FormControlLabel,
    Grid,
    Icon,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from '@material-ui/core';
import {MUTATION_ANNUAL_INSERT, MUTATION_DELETE_USER_FROM_LESSON, QUERY_USERS_BY_PK} from "../gql";
import {NavLink as Link, useParams} from 'react-router-dom';
import ClassScheduleViewer from "./ClassScheduleViewer";
import {classScheduleTimeFormatter, userNameFormatter} from "../utils/formatters";
import useMutationWithSnackbar from "./useMutationWithSnackbar";
import {useApolloClient} from '@apollo/react-hooks';

const YEARS_IN_ADVANCE = 2;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));


export default (props) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedClassSchedules, setSelectedClassSchedules] = useState([]);
    const apolloClient = useApolloClient();

    const {memoComp: MemoInsertSnackbar, doMutation: annualInsert, mutationLoading: annualInsertLoading} = useMutationWithSnackbar(MUTATION_ANNUAL_INSERT);
    const {memoComp: MemoDeleteSnackbar, doMutation: annualRemove, mutationLoading: annualRemoveLoading} = useMutationWithSnackbar(MUTATION_DELETE_USER_FROM_LESSON);

    const {user_id} = useParams();

    useEffect(() => {
        if (user_id) {
            (async () => {
                const {data: {users_by_pk}} = await apolloClient.query({
                    query: QUERY_USERS_BY_PK,
                    variables: {
                        id: user_id
                    }
                });
                setSelectedUsers([
                    ...selectedUsers,
                    users_by_pk
                ]);
            })();
        }
        return () => {

        }
    }, [setSelectedUsers, user_id, apolloClient]);

    function getSteps() {
        return [
            {
                title: 'FyioSporters selecteren',
                subtitle: selectedUsers.map(userNameFormatter).join(", ")
            },
            {
                title: 'Kies de dagen en de lestijden',
                subtitle: selectedClassSchedules.map(classScheduleTimeFormatter).join(", ")
            },
            {title: 'Wat wil je doen?', subtitle: ''}
        ];
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedUsers([]);
        setSelectedClassSchedules([]);
    };


    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Box pt={1}>
                        <UsersAutocomplete
                            value={selectedUsers}
                            onChange={(e, value) => {
                                setSelectedUsers(value);
                            }}
                        />
                    </Box>
                );
            case 1:
                return (
                    <Grid container style={{width: '100%', flexGrow: 1, paddingBottom: 20}} spacing={4}>
                        <ClassScheduleViewer renderItems={(classSchedule) => {
                            const {id, from_time, to_time} = classSchedule;
                            return (
                                <Box key={id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(selectedClassSchedules.filter(({id: _id}) => _id === id).length > 0)}
                                                onChange={(event, checked) => {
                                                    if (checked) {
                                                        setSelectedClassSchedules([
                                                            ...selectedClassSchedules,
                                                            classSchedule
                                                        ])
                                                    } else {
                                                        setSelectedClassSchedules(
                                                            selectedClassSchedules.filter(({id: _id}) => _id !== id)
                                                        )
                                                    }

                                                }}
                                                name="checkedB"
                                                color="secondary"
                                            />
                                        }
                                        label={from_time + " - " + to_time + " uur"}
                                    />
                                </Box>
                            )
                        }}/>
                    </Grid>
                );
            case 2:
                return <React.Fragment>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Box flexGrow={1} mr={4}>
                            <Card variant={"outlined"}>
                                <CardContent>
                                    <Typography variant={"body2"}>Ik wil de geselecteerde FysioSporters inplannen in
                                        de gekozen lessen</Typography>
                                    <Icon color={"error"} fontSize={"small"}>warning</Icon> <Typography color={"error"}
                                                                                                        variant={"caption"}>Let
                                    op! de sporters worden doorgepland tot en met 31
                                    dec {new Date().getFullYear() + YEARS_IN_ADVANCE}</Typography>
                                </CardContent>
                                <CardActions>
                                    {annualInsertLoading && <CircularProgress/>} &nbsp;
                                    <Button disabled={annualInsertLoading} color={"secondary"} size={"large"}
                                            onClick={async () => {
                                                await annualInsert({
                                                    variables: {
                                                        input: {
                                                            classSchedules: selectedClassSchedules.map(({from_time, to_time, day}) => {
                                                                return {
                                                                    from_time, to_time, day
                                                                }
                                                            }),
                                                            userIds: selectedUsers.map(({id}) => id),
                                                            yearsInAdvance: YEARS_IN_ADVANCE
                                                        }
                                                    }
                                                });
                                                handleNext();
                                            }}>Inplannen</Button>
                                </CardActions>
                            </Card>
                        </Box>
                        <Box flexGrow={1}>
                            <Card variant={"outlined"}>
                                <CardContent>
                                    <Typography variant={"body2"}>Ik wil de FysioSporters verwijderen uit de gekozen
                                        lessen</Typography>
                                    <Icon color={"error"} fontSize={"small"}>warning</Icon> <Typography color={"error"}
                                                                                                        variant={"caption"}>Let
                                    op! de sporters worden alleen uit hun eigen lessen gehaald</Typography>
                                </CardContent>
                                <CardActions>
                                    {annualRemoveLoading && <CircularProgress/>} &nbsp;
                                    <Button disabled={annualRemoveLoading} color={"secondary"} size={"large"}
                                            onClick={async () => {
                                                await annualRemove({
                                                    variables: {
                                                        where: {
                                                            user_id: {_in: selectedUsers.map(({id}) => id)},
                                                            lesson: {
                                                                lessons_class_schedules: {
                                                                    _or: selectedClassSchedules.map(({from_time, to_time, day}) => {
                                                                        return {
                                                                            class_schedule: {
                                                                                from_time: {_eq: from_time},
                                                                                to_time: {_eq: to_time},
                                                                                day: {_eq: day},
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                                handleNext();
                                            }}>Verwijderen</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Box>
                </React.Fragment>;
            default:
                return 'Unknown step';
        }
    }

    return (
        <div className={classes.root}>
            {MemoInsertSnackbar}
            {MemoDeleteSnackbar}
            <Typography variant={"h6"}>Door of uitplannen van de FysioSportes</Typography>
            <Typography variant={"subtitle2"}>U kunt maximaal {YEARS_IN_ADVANCE} jaar vooruit plannen</Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(({title, subtitle}, index) => (
                    <Step key={title}>
                        <StepLabel>{title}
                            <Typography variant={"caption"} component={"h4"}>{subtitle}</Typography>
                        </StepLabel>
                        <StepContent>
                            {getStepContent(index)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Terug
                                    </Button>
                                    {(!(activeStep === steps.length - 1)) && (activeStep === 0 && selectedUsers.length) ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        > Volgende </Button> : (activeStep === 1 && selectedClassSchedules.length) ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            > Volgende </Button> : null}
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography variant={"subtitle2"}><Icon
                        style={{color: colors.green["800"]}}>check_circle</Icon> Gegevens opgeslagen</Typography>
                    <hr/>
                    <Button onClick={handleReset} className={classes.button}>
                        Terug naar het begin
                    </Button>
                    <Button variant={"contained"} color={"primary"} className={classes.button} component={Link}
                            to={`/dashboard/scheduler`}>
                        <Icon>event</Icon> &nbsp; Bekijk de kalender
                    </Button>
                </Paper>
            )}
        </div>
    );
}

import React, {useState} from "react";
import {NavLink as Link, Redirect, Route, useRouteMatch} from "react-router-dom";
import useMemoStaticCalendar from "../components/useMemoStaticCalendar";
import useMemoScheduler from "../components/useMemoScheduler";
import CustomAppBar from "../components/AppBar";
import {Box, Button, Icon} from '@material-ui/core';
import ProfileMenu from "../components/ProfileMenu";
import UserScheduler from "../components/UserScheduler";

export default function () {
    let {path} = useRouteMatch();
    const [currentDate, setCurrentDate] = useState(new Date);
    const {comp: MemoScheduler, calendar} = useMemoScheduler({
        renderComp: UserScheduler,
        currentDate,
        setCurrentDate
    });
    const {comp: MemoStaticCalendar} = useMemoStaticCalendar({
        calendar,
        currentDate,
        setCurrentDate
    });

    return (
        <CustomAppBar toolbarChildren={() => {
            return <React.Fragment>
                <Box flexGrow={1}>

                </Box>
                <Button color={"inherit"} startIcon={<Icon>event</Icon>} aria-label="show 4 new mails" component={Link} exact to={`${path}/scheduler`}>
                    Mijn lesrooster
                </Button>
                <ProfileMenu/>
            </React.Fragment>
        }}>
            <Route path={`${path}/scheduler`}>
                <div style={{width: "100%"}}>
                    <Box display={"flex"}>
                        <Box>
                            {MemoStaticCalendar}
                        </Box>
                        <Box flexGrow={1}>
                            {MemoScheduler}
                        </Box>
                    </Box>
                </div>
            </Route>
            <Route path={`${path}/`}>
                <Redirect to={`${path}/scheduler`}/>
            </Route>
        </CustomAppBar>
    );
}

import React, {useMemo} from "react";
import {QUERY_IS_ME} from "../gql";
import {Link, Route} from "react-router-dom";
import {useQuery} from '@apollo/react-hooks';
import AppProgress from "./AppProgress";
import AdminIndex from "../pages/AdminIndex";
import UserIndex from "../pages/UserIndex";

export default function (props) {
    const {data, loading, error} = useQuery(QUERY_IS_ME);
    return (
        <Route
            {...props}
            render={() => {
                return (
                    (loading) ? <AppProgress/> : (data && data.me) ? (
                        data.me.admin ? <AdminIndex/> : <UserIndex/>
                    ) : <div>please redirect me to login, im not authenticated! <Link to={"/login"}>Inloggen</Link>
                    </div>
                )
            }}
        />
    );
}

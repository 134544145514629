import React, {useMemo} from "react";
import LessonsStaticCalendar from "./LessonsStaticCalendar";

export default ({calendar, currentDate, setCurrentDate}) => {

    return {
        comp: useMemo(() => {
            return <LessonsStaticCalendar
                value={currentDate}
                onChange={(date) => {
                    // console.log("onChange", date);
                    if (calendar && calendar.gotoDate) {
                        calendar.gotoDate(date);
                    }
                    setCurrentDate(date);
                }}
                onMonthChange={(date) => {
                    if (calendar && calendar.gotoDate) {
                        calendar.gotoDate(date);
                    }
                }}
            />
        }, [calendar, currentDate])
    }

};
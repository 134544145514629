import React from 'react';
import {userNameFormatter} from "../utils/formatters";
import {Button, Icon, Table, TableBody, TableCell, TableHead, TableRow,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {NavLink as Link} from 'react-router-dom';
import {MUTATION_SEND_NEW_PASSWORD} from "../gql";
import useMutationWithDialog from "./useMutationWithDialog";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const UsersListTable = ({users, onEditUser, onDeleteUser, ...rest}) => {

    // console.log("RENDER UsersListTable");

    const classes = useStyles();

    const {memoComp: SendPasswordEmailDialog, confirmMutation: sendPasswordToEmail} = useMutationWithDialog(MUTATION_SEND_NEW_PASSWORD);

    return (
        <React.Fragment>
            {SendPasswordEmailDialog}
            <Table className={classes.table} aria-label="simple table" {...rest}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Naam</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Wachtwoord</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        users.map((user) => {
                            const {id, email, password} = user;
                            return (
                                <TableRow key={id}>
                                    <TableCell component="th" scope="row">
                                        {id}
                                    </TableCell>
                                    <TableCell><Button endIcon={<Icon>event</Icon>} component={Link}
                                                       to={`/dashboard/user/${id}`}>{userNameFormatter(user)}</Button></TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>
                                        <Button startIcon={<Icon>email</Icon>} onClick={() => {
                                            sendPasswordToEmail({
                                                variables: {
                                                    email: user.email
                                                }
                                            }, {
                                                dialogTitle: "Nieuw wachtwoor versturen",
                                                dialogContent: `Nieuw wachtwoord versturen naar ${user.email}?`,
                                                positiveButtonText: "Ja, zeker!",
                                                negativeButtonText: "Annuleren",
                                            });
                                        }}>Verstuur wachtwoord</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button startIcon={<Icon>create</Icon>}
                                                color={"default"} onClick={(e) => {
                                            onEditUser(e, user);
                                        }}>Wijzigen</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button startIcon={<Icon>delete</Icon>}
                                                color={"secondary"}
                                                onClick={(e) => {
                                                    onDeleteUser(e, user);
                                                }}>Verwijderen</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );
};
export default React.memo(UsersListTable);
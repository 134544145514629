import {apolloClient} from "../apolloClient";
import {QUERY_BLOCKED_DATES, QUERY_LESSONS} from "../gql";
import lessonEventMapper from "../utils/lessonEventMapper";

export default {
    id: "lessonsEventSource",
    events: async ({end}, successCallback, failureCallback) => {
        const startDateString = window.moment().format('YYYY-MM-DD'),
            endDateString = window.moment(end).format('YYYY-MM-DD');
        try {

            const {data: {blocked_dates}} = await apolloClient.query({
                fetchPolicy: "no-cache",
                query: QUERY_BLOCKED_DATES
            });
            const where = {
                where: {
                    _and: [
                        {
                            date: {_gte: startDateString},
                        }, {
                            date: {_lte: endDateString},
                        },
                        {
                            _not: {
                                date: {_in: blocked_dates.map(({date}) => date)}
                            }
                        }
                    ]
                }
            };
            const {data: {lessons}} = await apolloClient.query({
                fetchPolicy: "no-cache",
                query: QUERY_LESSONS, variables: where
            });
            successCallback(lessonEventMapper(lessons));
        } catch (e) {
            failureCallback(e);
        }
    },
    className: "lesson-event",
    color: "#3f51b5"
};
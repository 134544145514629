import React from 'react';
import {Box, Icon} from '@material-ui/core';

const UserSchedulerLegend = () => {
    return (
        <React.Fragment>
            <Box display={"flex"} justifyContent={"space-evenly"} width={400}>
                <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                    <Box width={10} height={10} bgcolor={"primary.main"}/>
                    <Box pl={1}>
                        Les
                    </Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                    <Box width={10} height={10} bgcolor={"primary.main"} style={{opacity: 0.6}}/>
                    <Box pl={1}>
                        Niet beschikbaar
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                    <Icon color={"primary"} fontSize={"small"}>group</Icon>
                    <Box pl={1}>
                        Bezetting
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                    <Icon color={"primary"} fontSize={"small"}>crop_free</Icon>
                    <Box pl={1}>
                        Vrij
                    </Box>
                </Box>

            </Box>
        </React.Fragment>
    );
};

export default UserSchedulerLegend;
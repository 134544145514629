import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {QUERY_WEEK_LESSONS_CLASS_SCHEDULES} from "../gql";

const ClassScheduleViewer = ({renderItems, gridItems = 4, cardHeight = 260}) => {
    const classSchedulesResult = useQuery(QUERY_WEEK_LESSONS_CLASS_SCHEDULES);

    return (
        <React.Fragment>
            {!classSchedulesResult.loading && classSchedulesResult.data && Object.keys(classSchedulesResult.data).map((dayString) => {
                const schedulesOfTheDay = classSchedulesResult.data[dayString];
                return (
                    <Grid item xs={gridItems} key={dayString}>
                        <Card style={{height: cardHeight }} variant={"outlined"}>
                            <CardContent>
                                <Typography variant={"h5"} component={"h2"}>{dayString}</Typography>
                                {schedulesOfTheDay.map(renderItems)}
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </React.Fragment>
    );
};

export default ClassScheduleViewer;
import React, {useCallback, useState} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {Box, Button, Grid, Icon, Typography} from '@material-ui/core';
import {useQuery} from '@apollo/react-hooks'
import {MUTATION_DELETE_USERS, QUERY_USERS} from "../gql";
import AppProgress from "./AppProgress";
import {userNameFormatter} from "../utils/formatters";
import UsersAutocomplete from "./UsersAutocomplete";
import useDialog from "./useDialog";
import AddUserDialog from "./AddUserDialog";
import useMutationWithDialog from "./useMutationWithDialog";
import UsersListTable from "./UsersListTable";


export default () => {
    // console.log("USERSLISTMANAGER");

    const whereNotAdminUser = {
        where: {
            _not: {
                email: {
                    _eq: "admin@fysiozwanenzijde.nl"
                }
            }
        }
    };

    const usersResult = useQuery(QUERY_USERS, {
        variables: whereNotAdminUser,
    });


    const [filteredUsers, setFilteredUsers] = useState([]);


    const {memoComp: MemoAddUserDialog, openDialog: openAddUserDialog} = useDialog({
        component: ({onClose, ...rest}) => {
            return <AddUserDialog {...rest} onClose={onClose} onMutationCompleted={async () => {
                await usersResult.refetch(whereNotAdminUser);
                onClose();
                resetData();
            }}/>
        }
    });

    const {memoComp: MemoEditUserDialog, openDialog: openEditUserDialog} = useDialog({
        component: ({onClose, data, ...rest}) => {
            return <AddUserDialog editFormData={data} {...rest} onClose={onClose} onMutationCompleted={async () => {
                await usersResult.refetch(whereNotAdminUser);
                onClose();
                resetData();
            }}/>
        }
    });

    const {memoComp: DeleteUserConfirmationDialog, mutationLoading: deleteMutationLoading, confirmMutation: deleteUser} = useMutationWithDialog(
        MUTATION_DELETE_USERS,
        {
            onCompleted: () => {
                resetData();
            },
            refetchQueries: [
                {
                    query: QUERY_USERS,
                    variables: whereNotAdminUser
                }
            ]
        }
    );

    const [searchUsers, setSearchUsers] = useState([]);

    const resetData = useCallback(() => {
        setFilteredUsers([]);
        setSearchUsers([]);
    }, []);

    return (
        <React.Fragment>
            {DeleteUserConfirmationDialog}
            {MemoAddUserDialog}
            {MemoEditUserDialog}
            {(usersResult.loading || deleteMutationLoading) && <AppProgress/>}
            {usersResult.data && usersResult.data.users &&
            <TableContainer component={Paper}>
                <Box p={2}>
                    <Typography variant={"h5"}>Alle ingeschreven FysioSporters</Typography>
                </Box>

                <Grid container>
                    <Grid item xs={4}>
                        <Box p={2}>
                            <UsersAutocomplete value={searchUsers} label={"Zoek naar FysioSporters"}
                                               autoFocus={true}
                                               onChange={(e, value) => {
                                                   if (value) {
                                                       setFilteredUsers(usersResult.data.users.filter((user) => {
                                                           return value.filter(({id}) => {
                                                               return id === user.id
                                                           }).length > 0;
                                                       }));
                                                       setSearchUsers(value);
                                                   } else {
                                                       resetData();
                                                   }
                                               }}/>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box p={2} display={"flex"} justifyContent="flex-end">
                            <Button variant={"contained"} color={"primary"} startIcon={<Icon>add_circle</Icon>}
                                    onClick={openAddUserDialog}> Nieuwe FysioSporter</Button>
                        </Box>
                    </Grid>

                </Grid>
                <UsersListTable users={filteredUsers.length ? filteredUsers : usersResult.data.users}
                                onDeleteUser={(e, user) => {
                                    const {id} = user;
                                    deleteUser({
                                        variables: {
                                            where: {
                                                id: {_eq: id}
                                            }
                                        }
                                    }, {
                                        dialogTitle: `${userNameFormatter(user)}`,
                                        dialogContent: `Weet u zeker dat u ${userNameFormatter(user)} wilt verwijderen uit het systeem?`
                                    });
                                }} onEditUser={(e, user) => {
                    openEditUserDialog(user);
                }}/>
            </TableContainer>
            }
        </React.Fragment>
    );
}

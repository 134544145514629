import React from "react";
import {render} from "react-dom";
import "./index.scss";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {QUERY_IS_ME} from "./gql";
import {apolloClient} from "./apolloClient";
import {ApolloProvider, useQuery} from "@apollo/react-hooks";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AppProgress from "./components/AppProgress";
import Login from "./pages/Login";

const AppRouter = () => {
    const {data, loading, error} = useQuery(QUERY_IS_ME);
    return <Router>
        <Switch>
            <Route path="/login" render={() => {
                return (
                    (loading) ? <AppProgress/> : (data && data.me) ? <Redirect to={"/dashboard"}/> : <Login />
                )
            }}>
            </Route>
            <AuthenticatedRoute path="/dashboard"/>
            <Route exact path="/" render={() => {
                return (
                    (loading) ? <AppProgress/> : (data && data.me) ? <Redirect to={"/dashboard"}/> : <Login />
                )
            }}>
            </Route>
        </Switch>
    </Router>
};

function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <AppRouter/>
        </ApolloProvider>
    );
}

render(<App/>, document.getElementById("root"));

import React, {useState, useMemo} from "react";
import {useMutation} from "@apollo/react-hooks";
import {
    Snackbar,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {has} from "lodash";

export default (mutation, mutationHookOptions) => {
    const [snackbarOptions, setSnackbarOptions] = useState({
        open: false,
        serverity: "success",
        message: "",
    });

    const [_doMutation, {loading: mutationLoading}] = useMutation(mutation, {
        ...mutationHookOptions,
        onCompleted: () => {
            if (has(mutationHookOptions, "onCompleted"))
                mutationHookOptions.onCompleted();
            setSnackbarOptions({
                open: true,
                message: snackbarOptions.message || "Gegevens opgeslagen",
                serverity: snackbarOptions.serverity || "success",
            });
        },
        onError: (e) => {
            if (has(mutationHookOptions, "onError")) mutationHookOptions.onError(e);
            setSnackbarOptions({
                open: true,
                message: snackbarOptions.message || "Opslaan is niet gelukt",
                serverity: snackbarOptions.serverity || "error",
            });
        },
    });
    return {
        memoComp: useMemo(() => {
            const handleSnackbarClose = () => {
                setSnackbarOptions({open: false});
            };
            return (
                <React.Fragment>
                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                        open={snackbarOptions.open}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarOptions.serverity}
                        >
                            {snackbarOptions.message}
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            );
        }, [snackbarOptions]),
        doMutation: (mutationFunctionOptions, snackbarOptions) => {
            _doMutation(mutationFunctionOptions);
            setSnackbarOptions({
                ...snackbarOptions,
            });
        },
        mutationLoading
    };
};

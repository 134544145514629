import React, {createRef, useMemo, useState} from "react";

export default ({renderComp: Comp, currentDate, setCurrentDate}) => {
    const schedulerRef = createRef();
    const [calendar, setCalendar] = useState();
    return {
        comp: useMemo(() => {
            return (
                <Comp
                    forwardRef={schedulerRef}
                    datesRender={(info) => {
                        setCurrentDate(info.view.context.calendar.getDate());
                        setCalendar(info.view.context.calendar);
                    }}
                />
            )
        }, [currentDate]),
        calendar
    };
}

import React, {useState} from "react";
import {NavLink as Link, Redirect, Route, useRouteMatch} from "react-router-dom";
import BlockedDatesCalendar from "../components/BlockedDatesCalendar";
import useMemoStaticCalendar from "../components/useMemoStaticCalendar";
import useMemoScheduler from "../components/useMemoScheduler";
import CustomAppBar from "../components/AppBar";
import {Box, Button, Icon} from '@material-ui/core';
import ProfileMenu from "../components/ProfileMenu";
import AnnualEventManager from "../components/AnnualEventManager";
import AdminScheduler from "../components/AdminScheduler";
import LiteUserIndex from "./LiteUserIndex";
import UsersListManager from "../components/UsersListManager";

export default function () {
    let {path} = useRouteMatch();
    const [currentDate, setCurrentDate] = useState(new Date);


    const {comp: MemoScheduler, calendar} = useMemoScheduler({
        renderComp: AdminScheduler,
        currentDate,
        setCurrentDate
    });

    const {comp: MemoStaticCalendar} = useMemoStaticCalendar({
        calendar,
        currentDate,
        setCurrentDate
    });

    const {comp: MemoBlockedDatesScheduler, calendar: blockedDatesCalendar} = useMemoScheduler({
        renderComp: BlockedDatesCalendar,
        currentDate,
        setCurrentDate
    });

    const {comp: MemoBlockedDatesStaticCalendar} = useMemoStaticCalendar({
        calendar: blockedDatesCalendar,
        currentDate,
        setCurrentDate
    });

    return (
        <div>
            <CustomAppBar hasSearchBar={true} toolbarChildren={() => {
                return <React.Fragment>
                    <Button color={"inherit"} startIcon={<Icon>group</Icon>} aria-label="show 4 new mails" component={Link}
                            to={`${path}/users`}>
                        FysioSporters
                    </Button>
                    <Button color={"inherit"} startIcon={<Icon>date_range</Icon>} aria-label="show 4 new mails" component={Link}
                            to={`${path}/annual-events`}>
                        Doorplannen
                    </Button>
                    <Button color={"inherit"} startIcon={<Icon>event</Icon>} aria-label="show 4 new mails" component={Link}
                            to={`${path}/scheduler`}>
                        Kalender
                    </Button>
                    <Button color={"inherit"} startIcon={<Icon>event_busy</Icon>} aria-label="show 4 new mails" component={Link}
                            to={`${path}/blocked-dates`}>
                        Blokkeren
                    </Button>
                    <ProfileMenu/>
                </React.Fragment>
            }}>
                <Route path={`${path}/scheduler`}>
                    <div style={{width: "100%"}}>
                        <Box display={"flex"}>
                            <Box>
                                {MemoStaticCalendar}
                            </Box>
                            <Box flexGrow={1}>
                                {MemoScheduler}
                            </Box>
                        </Box>
                    </div>
                </Route>
                <Route path={`${path}/blocked-dates`}>
                    <div style={{width: "100%"}}>
                        <Box display={"flex"}>
                            <Box>
                                {MemoBlockedDatesStaticCalendar}
                            </Box>
                            <Box flexGrow={1}>
                                {MemoBlockedDatesScheduler}
                            </Box>
                        </Box>
                    </div>
                </Route>
                <Route path={`${path}/annual-events/:user_id?`}>
                    <div style={{width: "100%"}}>
                        <Box display={"flex"}>
                            <AnnualEventManager/>
                        </Box>
                    </div>
                </Route>
                <Route exact path={`${path}/users`}>
                    <div style={{width: "100%"}}>
                        <Box display={"flex"}>
                            <UsersListManager />
                        </Box>
                    </div>
                </Route>
                <Route path={`${path}/user/:user_id`}>
                    <LiteUserIndex/>
                </Route>
                <Route exact path={`${path}/`}>
                    <Redirect to={`${path}/scheduler`}/>
                </Route>
            </CustomAppBar>
        </div>
    );
}

import React from 'react';
import {Icon, IconButton, Menu, MenuItem, Typography} from '@material-ui/core';
import {Link, useRouteMatch} from 'react-router-dom';
import {useQuery} from "@apollo/react-hooks";
import {userNameFormatter} from "../utils/formatters";
import {QUERY_IS_ME} from "../gql";

export default () => {
    let {path} = useRouteMatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const meResult = useQuery(QUERY_IS_ME);

    return <React.Fragment>
        <IconButton
            edge={"end"}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color={"inherit"}
        >
            <Icon>account_circle</Icon>
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
        >
            {!meResult.loading && meResult.data && meResult.data.me && <MenuItem disabled={true}>{meResult.data.me.name}</MenuItem>}
            <MenuItem onClick={() => {
                localStorage.removeItem("fysiosport-token");
                setTimeout(() => {
                    window.location = '/login';
                }, 0);
            }} component={Link} to={`${path}`}>
                Uitloggen
            </MenuItem>

        </Menu>
    </React.Fragment>
}

import gql from "graphql-tag";

export const typeDefs = gql`
    extend type Query {
        hello: String
    }
`;
export const resolvers = {};

export const QUERY_IS_ME = gql`
    query me {
        me {
            id
            name
            admin
        }
    }
`;

export const MUTATION_DELETE_USER_FROM_LESSON = gql`
    mutation delete_users_lessons($where:  users_lessons_bool_exp!) {
        delete_users_lessons(where: $where) {
            affected_rows
        }
    }
`;

export const QUERY_USERS_BY_PK = gql`
    query users_by_pk($id:Int!) {
        users_by_pk(id:$id) {
            id
            first_name
            last_name
            infix
        }
    }
`;

export const QUERY_USERS = gql`
    query users($where: users_bool_exp) {
        users(where: $where, order_by: {last_name: asc}) {
            id
            first_name
            last_name
            infix
            email
            password
        }
    }
`;

export const SUBSCRIPTION_USERS = gql`
    subscription users($where: users_bool_exp) {
        users(where: $where, order_by: {last_name: asc}) {
            id
            first_name
            last_name
            infix
            email
        }
    }
`;

export const MUTATION_INSERT_USERS = gql`
    mutation insert_users($data: [users_insert_input!]!) {
        insert_users(objects: $data
            on_conflict:{
                constraint:users_pkey
                update_columns: [last_name, first_name, infix, email]
            }
        ) {
            affected_rows
        }
    }
`;

export const MUTATION_REGISTER_NEW_USER = gql`
    mutation register_new_user($data:  RegisterUserInput!) {
        register_new_user(user: $data) {
            affected_rows
        }
    }
`;


export const MUTATION_DELETE_USERS = gql`
    mutation delete_users($where: users_bool_exp!) {
        delete_users(where:$where) {
            affected_rows
        }
    }
`;
export const QUERY_LESSONS = gql`
    query lessons($where: lessons_bool_exp $limit:Int) {
        lessons(order_by: { date: asc }  where: $where  limit: $limit) {
            users_lessons(order_by: { user: { last_name: asc } }) {
                user {
                    id
                    first_name
                    last_name
                    infix
                }
            }
            date
            id
            users_lessons_aggregate {
                aggregate {
                    count
                }
            }
            lessons_class_schedules(order_by: {class_schedule: {from_time: asc}}) {
                class_schedule {
                    from_time
                    to_time
                }
            }
        }
    }
`;

export const QUERY_BLOCKED_DATES = gql`
    query blocked_dates($where: blocked_dates_bool_exp) {
        blocked_dates(order_by: { date: asc }, where: $where) {
            id
            title
            date
        }
    }
`;

export const SUBSCRIPTION_BLOCKED_DATES = gql`
    subscription blocked_dates($where: blocked_dates_bool_exp) {
        blocked_dates(order_by: { date: asc }, where: $where) {
            id
            title
            date
        }
    }
`;

export const QUERY_LESSON_BY_ID = gql`
    query lesson($lesson_id: Int!) {
        lessons_by_pk(id: $lesson_id) {
            date
            id
            users_lessons_aggregate {
                aggregate {
                    count
                }
            }
            users_lessons(order_by: {user: {last_name: asc}}) {
                user {
                    first_name
                    id
                    infix
                    last_name
                }
            }
        }
    }
`;

export const MUTATION_ADD_USERS_TO_LESSON = gql`
    mutation insert_lessons($data: [lessons_insert_input!]!) {
        insert_lessons(objects: $data
            on_conflict:{
                constraint:lessons_pkey
                update_columns:[id]
            }
        ) {
            affected_rows
        }
    }
`;

export const SUBSCRIPTION_LESSONS = gql`
    subscription users_lessons($where: lessons_bool_exp) {
        lessons(where: $where) {
            date
            id
            users_lessons {
                user {
                    email
                    first_name
                    last_name
                    id
                }
            }
        }
    }
`;

export const MUTATION_INSERT_BLOCKED_DATES = gql`
    mutation($data:  [blocked_dates_insert_input!]!) {
        insert_blocked_dates(
            objects: $data
            on_conflict:{
                constraint:blocked_dates_date_key
                update_columns:[]
            }) {
            affected_rows
        }
    }
`;

export const MUTATION_DELETE_BLOCKED_DATES = gql`
    mutation delete_blocked_dates($where: blocked_dates_bool_exp!) {
        delete_blocked_dates(where: $where) {
            affected_rows
        }
    }
`;

export const QUERY_WEEK_LESSONS_CLASS_SCHEDULES = gql`

    query {
        maandag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: maandag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
        dinsdag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: dinsdag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
        woensdag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: woensdag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
        donderdag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: donderdag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
        vrijdag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: vrijdag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
        zaterdag: class_schedules(order_by: {from_time: asc}, where: {day: {_eq: zaterdag}, type: {_eq: algemeen}, location: {_eq: merendonk}}) {
            day
            from_time
            id
            to_time
        }
    }

`;

export const MUTATION_ANNUAL_INSERT = gql`
    mutation annual_insert ($input: AnnualInsertInput!) {
        annual_insert(input:$input) {
            affected_rows
        }
    }
`;

export const MUTATION_SEND_NEW_PASSWORD = gql`
    mutation  send_new_password($email:String!) {
        send_new_password(email:$email) {
            affected_rows
        }
    }
`